import './bootstrap';



import SlimSelect from 'slim-select'
import 'simplebar';







function random(min, max) {
    let randomNum = Math.floor(Math.random() * (max - min + 1)) + min;
    return randomNum
};

function loadLiteYoutube()
{
    if(document.querySelector("lite-youtube")  && !document.querySelector("#lite-youtube-embed")){
        let link1 = document.createElement('link');
        link1.setAttribute('rel', "stylesheet");
        link1.setAttribute('href', "/lite-youtube-embed/src/lite-yt-embed.css");
        document.querySelector('head').appendChild(link1);

        let script1 = document.createElement('script')
        script1.src = "/lite-youtube-embed/src/lite-yt-embed.js"
        script1.setAttribute('id', 'lite-youtube-embed')
        document.querySelector('head').appendChild(script1)


    }
}

function addLazyLoading()
{
    // ждем полной загрузки страницы
    window.onload = () => {

        const arr = document.querySelectorAll('[loading=lazy]')

        // создаем наблюдатель
        const observer = new IntersectionObserver((entries, observer) => {
            // для каждой записи-целевого элемента
            entries.forEach(entry => {

                // если элемент является наблюдаемым
                if (entry.isIntersecting) {
                    const lazyImg = entry.target
                    makeImgLazy(lazyImg)
                    // прекращаем наблюдение
                    observer.unobserve(lazyImg)
                }
            })
        })

        // с помощью цикла следим за всеми img на странице
        arr.forEach(i => {
            observer.observe(i)
        })

    }
}

function loadWidgets()
{
    const section1 = document.querySelectorAll('.section1 .block_from_post, .custom-widget, .custom-block')
    addDisplayBlockToElements(section1)
    const section2 = document.querySelectorAll('.section2 .block_from_post, .custom-widget, .custom-block')
    addDisplayBlockToElements(section2)
    const section3 = document.querySelectorAll('.section3 .block_from_post, .custom-widget, .custom-block')
    addDisplayBlockToElements(section3)
    const section4 = document.querySelectorAll('.section4 .block_from_post, .custom-widget, .custom-block')
    addDisplayBlockToElements(section4)

    const section5 = document.querySelectorAll('.section5 .block_from_post, .custom-widget, .custom-block')
    addDisplayBlockToElements(section5)
    let sections = document.querySelectorAll('.section1, .section2, .section3, .section4, .section5')
    const sectionsObserver = new IntersectionObserver((entries, observer) => {
        // для каждой записи-целевого элемента
        entries.forEach(entry => {

            // если элемент является наблюдаемым
            if (entry.isIntersecting) {
                let element = entry.target;
                let elements = element.querySelectorAll(' .block_from_post, .custom-widget, .custom-block')
                if(elements[0] && elements[0].style && elements[0].style.display==='none'){
                    elements[0].style.removeProperty("display");
                }
            }
        })
    })

    sections.forEach(section => {
        sectionsObserver.observe(section)
    })
}

function addDisplayBlockToElements(elements)
{
    const observer = new IntersectionObserver((entries, observer) => {
        // для каждой записи-целевого элемента
        entries.forEach(entry => {

            // если элемент является наблюдаемым
            if (entry.isIntersecting) {
                const element = entry.target;
                if(element.style.display === 'none') element.style.removeProperty("display");
                observer.unobserve(element)
                let nextSibling = element.nextSibling;
                let isContainsGoodClass = false;
                let arrGoodClasses = ["block_from_post", "custom-widget", "custom-block"];
                for(let i = 0; i< arrGoodClasses.length; i++){
                    if(nextSibling && nextSibling.classList && nextSibling.classList.contains( arrGoodClasses[i] )){
                        isContainsGoodClass = true
                        break;
                    }
                }
                if(nextSibling && nextSibling.style && isContainsGoodClass){
                    nextSibling.style.removeProperty("display");
                    observer.observe(nextSibling)

                    if(nextSibling && nextSibling.classList && nextSibling.classList.contains("xl:hidden")){
                        nextSibling = nextSibling.nextSibling;
                        isContainsGoodClass =  false;
                        for(let i = 0; i< arrGoodClasses.length; i++){
                            if(nextSibling && nextSibling.classList && nextSibling.classList.contains( arrGoodClasses[i] )){
                                isContainsGoodClass = true
                                break;
                            }
                        }
                        if(nextSibling && nextSibling.style && isContainsGoodClass){
                            nextSibling.style.removeProperty("display");
                            observer.observe(nextSibling)
                        }

                    }
                }


            }
        })
    })


    elements.forEach(i => {
        observer.observe(i)
    })
}


function makeImgLazy(lazyImg)
{

    let picture = lazyImg.parentElement
    if(picture && picture.tagName && picture.tagName === "PICTURE"){
        let sources =  picture.querySelectorAll("source");
        sources.forEach(function (source, index){
            if(sources[index].dataset.srcset)
                sources[index].srcset = sources[index].dataset.srcset
        })
    }

    lazyImg.src = lazyImg.dataset.src
}





// to move pages scripts
// post page







function copyLinkOfPost(){
    document.querySelectorAll(".copy_link_of_post").forEach(function (item){
        item.addEventListener('click', function (){
            try{
                let postLink = item.getAttribute('data-post-link');
                if(postLink){
                    if(postLink.startsWith("//")){
                        postLink = "https:"+postLink
                    }
                    copyText(postLink)

                    if(document.querySelector("[name=copy_link_of_post_alert]"))
                        alert(document.querySelector("[name=copy_link_of_post_alert]").getAttribute('value'))
                }
            }catch (e){
                console.log(e)
            }


        })
    })
}

function isJsonString(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}




window.addEventListener("load", function(){
    if (window.matchMedia('(min-width: 1024px)').matches) {
        class StickerToTop {
            constructor(selector) {
                this.selector = selector;
                this.targetPosition1 = {
                    top: window.pageYOffset + document.querySelector(this.selector).getBoundingClientRect().top,
                    left: window.pageXOffset + document.querySelector(this.selector).getBoundingClientRect().left,
                    right: window.pageXOffset + document.querySelector(this.selector).getBoundingClientRect().right,
                    bottom: window.pageYOffset + document.querySelector(this.selector).getBoundingClientRect().bottom,
                    width: document.querySelector(this.selector).getBoundingClientRect().width,
                    height: document.querySelector(this.selector).getBoundingClientRect().height
                };
                this.initialTop = document.querySelector(this.selector).offsetTop;
                this.initialBottom = document.querySelector(this.selector).offsetTop + document.querySelector(this.selector).getBoundingClientRect().height;
                this.footerPosition = {
                    top: window.pageYOffset + document.querySelector("footer").getBoundingClientRect().top
                }
            }

            isElementIntersectingWithFooter() {

                if (this.footerPosition.top < document.querySelector(this.selector).getBoundingClientRect().bottom + window.pageYOffset) {
                    return true
                } else {
                    return false
                }

            }

            stickElementToTopIfItUpperScroll() {
                this.footerPosition.top =  window.pageYOffset + document.querySelector("footer").getBoundingClientRect().top;
                let windowPosition = {
                    top: window.pageYOffset,
                    left: window.pageXOffset,
                    right: window.pageXOffset + document.documentElement.clientWidth,
                    bottom: window.pageYOffset + document.documentElement.clientHeight
                };
                if(document.querySelector(this.selector).style.display!=='none'){
                    if (windowPosition.bottom+this.targetPosition1.height < this.footerPosition.top) {
                        let isRemoveAttributeStyle = false
                        if(document.documentElement.clientHeight > this.targetPosition1.height){
                            isRemoveAttributeStyle = this.initialTop > windowPosition.top;
                        }else{
                            isRemoveAttributeStyle = this.initialBottom > windowPosition.bottom
                        }

                        if (isRemoveAttributeStyle) {
                            document.querySelector(this.selector).removeAttribute('style')
                            this.initialTop = document.querySelector(this.selector).offsetTop;
                            this.initialBottom = document.querySelector(this.selector).offsetTop + document.querySelector(this.selector).getBoundingClientRect().height;
                            this.targetPosition1.height = document.querySelector(this.selector).getBoundingClientRect().height;
                        } else {
                            if(document.documentElement.clientHeight > this.targetPosition1.height){
                                let top = 0;
                                document.querySelector(this.selector).setAttribute("style", `position:fixed; top:${top}px; width:${this.targetPosition1.width}px;`)
                            }else{
                                let bottom = 0;
                                document.querySelector(this.selector).setAttribute("style", `position:fixed; bottom:${bottom}px; width:${this.targetPosition1.width}px;`)
                            }

                        }
                    }else if(document.querySelector(this.selector) && document.querySelector(this.selector).style && document.querySelector(this.selector).style.position==='fixed'){
                        let distanceBetweenTopOfFooterAndBottomOfScreen = document.documentElement.clientHeight - document.querySelector("footer").getBoundingClientRect().top
                        if(document.documentElement.clientHeight > this.targetPosition1.height) {
                            let top = 0;
                            if (distanceBetweenTopOfFooterAndBottomOfScreen > 0) {
                                top = top - distanceBetweenTopOfFooterAndBottomOfScreen
                            }
                            document.querySelector(this.selector).setAttribute("style", `position:fixed; top:${top}px; width:${this.targetPosition1.width}px;`)
                        }else{
                            let bottom = 0;
                            if (distanceBetweenTopOfFooterAndBottomOfScreen > 0) {
                                bottom = bottom + distanceBetweenTopOfFooterAndBottomOfScreen
                            }
                            document.querySelector(this.selector).setAttribute("style", `position:fixed; bottom:${bottom}px; width:${this.targetPosition1.width}px;`)
                        }
                    }
                }

            }
        }

        let stickerToTop1 = null;
        let stickerToTop2 = null;
        let stickerToTop3 = null;
        initStickerToTop()

        addEventListener("resize", (event) => {
            initStickerToTop()

        });

        function initStickerToTop() {
            stickerToTop1 = null;
            stickerToTop2 = null;
            stickerToTop3 = null;
            for (let index = 1; index <= 3; index++) {
                if (document.querySelector(`.last_widget${index}`)) {
                    document.querySelector(`.last_widget${index}`).removeAttribute('style');
                }
            }

            if (document.querySelector('.last_widget1') && window.matchMedia('(min-width: 1280px)').matches)
                stickerToTop1 = new StickerToTop('.last_widget1');
            if (document.querySelector('.last_widget2'))
                stickerToTop2 = new StickerToTop('.last_widget2');
            if (document.querySelector('.last_widget3'))
                stickerToTop3 = new StickerToTop('.last_widget3');
            document.addEventListener('scroll', function (){
                if (document.querySelector('.last_widget3') && stickerToTop3)
                    stickerToTop3.stickElementToTopIfItUpperScroll()
                if (document.querySelector('.last_widget2') && stickerToTop2)
                    stickerToTop2.stickElementToTopIfItUpperScroll()
                if (document.querySelector('.last_widget1') && stickerToTop1)
                    stickerToTop1.stickElementToTopIfItUpperScroll()
            }, {passive: true});
        }

    }
});
document.addEventListener('DOMContentLoaded', () => {
    loadWidgets()
    loadLiteYoutube();
    document.querySelectorAll(".vote-widget").forEach(function (voteWidgetItem){
        if(localStorage.userVotes){
            let dataWidgetId = voteWidgetItem.getAttribute('data-widget-id');
            let userVotes = JSON.parse( localStorage.userVotes );
            fetch(`/poll-widget-info/${dataWidgetId}`).then(function (r){
                return r.json()
            }).then(function (data){

                let userVote = userVotes.find(function (item){

                    return item['widgetId'] && item['widgetId']==dataWidgetId;
                })



                if(userVote && userVote.voteId &&  data.variants_of_poll && data.sum_of_number_of_votes){

                    document.querySelectorAll(`div[data-widget-id="${dataWidgetId}"] label`).forEach(function (item){
                        item.classList.add('is--results')
                    })

                    document.querySelectorAll(`div[data-widget-id="${dataWidgetId}"] input[data-id-of-variant="${userVote.voteId}"]`).forEach(function (item, key){
                        document.querySelectorAll(`div[data-widget-id="${dataWidgetId}"] input[data-id-of-variant="${userVote.voteId}"]`)[key].checked = true;
                    })

                    data.variants_of_poll.forEach(function (variantOfPoll){
                        if(Number.isInteger(variantOfPoll.number_of_votes)){
                            let percent = Math.round(variantOfPoll.number_of_votes*100/data.sum_of_number_of_votes)

                            document.querySelectorAll(`div[data-id-of-variant-view-result="${variantOfPoll.id}"]`).forEach(function (item){
                                item.setAttribute('style', `width: ${percent}%`)
                            })
                            document.querySelectorAll(`span[data-id-of-variant-numeric-result="${variantOfPoll.id}"]`).forEach(function (item){
                                item.innerText = `${percent}%`
                            })

                        }

                    })

                    document.querySelectorAll(`div[data-widget-id="${dataWidgetId}"] .vote-widget-value`).forEach(function (item){
                        item.style.display = 'block'
                    })
                    document.querySelectorAll(`div[data-widget-id="${dataWidgetId}"] .vote-widget-value .number-of-votes`).forEach(function (item){
                        item.innerText = data.sum_of_number_of_votes
                    })

                    document.querySelectorAll(`div[data-widget-id="${dataWidgetId}"] .button-for-vote`).forEach(function (item){
                        item.style.display = 'none'
                    })
                    document.querySelectorAll(`div[data-widget-id="${dataWidgetId}"] .vote-result`).forEach(function (item){
                        item.style.display = 'block'
                    })
                }
            })
        }
    })





    copyLinkOfPost()
    // checker
    const useItemChecker = (els, onClickOutside) => {
        const checkBodyClick = (e) => {
            let currentEl = e.target;

            while (currentEl) {
                if (els.includes(currentEl)) break;
                currentEl = currentEl.parentNode
            }

            if (!currentEl) {
                onClickOutside()
                removeBodyChecker()
            }
        }
        function setBodyChecker  ()  {
            document.documentElement.addEventListener('click', checkBodyClick)
        }
        function removeBodyChecker ()  {
            document.documentElement.removeEventListener('click', checkBodyClick)
        }
        return {setBodyChecker, removeBodyChecker}
    }

    // change theme by clicking a button
    const toggleDarkThemeBtn = document.getElementById('toggle-dark-theme');

    const enableDarkMode = () => {
        localStorage.theme = 'dark'

        document.documentElement.classList.add('dark');
    };

    const disableDarkMode = () => {
        localStorage.theme = 'light'

        document.documentElement.classList.remove('dark');
    };

    if (toggleDarkThemeBtn) {
        toggleDarkThemeBtn.addEventListener('click', (event) => {
            event.preventDefault()

            if (localStorage.theme === 'dark' || (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
                disableDarkMode();
            } else {
                enableDarkMode();
            }
        });
    }

    // textarea
    const allTexareas = document.querySelectorAll('textarea')

    if (allTexareas) {
        allTexareas.forEach(el => {
            el.style.height = el.setAttribute('style', 'height: ' + el.scrollHeight + 'px');
            el.classList.add('auto');
            el.addEventListener('input', e => {
                el.style.height = 'auto';
                el.style.height = (el.scrollHeight) + 'px';
            });
        });
    }

    // select
    const customSelects = document.querySelectorAll('.custom-select')

    if (customSelects) {
        customSelects.forEach(item => {
            new SlimSelect({
                select: item,
                settings: {
                    showSearch: false,
                }
            })
        })
    }



    // slides up/down/toggle
    let slideUpQna = (target, duration = 400) => {
        target.style.transitionProperty = 'height, margin, padding';
        target.style.transitionDuration = duration + 'ms';
        target.style.height = target.offsetHeight + 'px';
        target.offsetHeight;
        target.style.overflow = 'hidden';
        target.style.height = 0;
        target.style.paddingTop = 0;
        target.style.paddingBottom = 0;
        target.style.marginTop = 0;
        target.style.marginBottom = 0;
        target.previousElementSibling.style.pointerEvents = 'none';
        window.setTimeout(() => {
            target.style.display = 'none';
            target.style.removeProperty('height');
            target.style.removeProperty('padding-top');
            target.style.removeProperty('padding-bottom');
            target.style.removeProperty('margin-top');
            target.style.removeProperty('margin-bottom');
            target.style.removeProperty('overflow');
            target.style.removeProperty('transition-duration');
            target.style.removeProperty('transition-property');
            target.previousElementSibling.style.pointerEvents = 'auto';
        }, duration);
        target.parentNode.classList.remove('is--open');
    }

    let slideDownQna = (target, duration = 400) => {
        target.style.removeProperty('display');
        let display = window.getComputedStyle(target).display;
        if (display === 'none') display = 'block';
        target.style.display = display;
        let height = target.offsetHeight;
        target.style.overflow = 'hidden';
        target.style.height = 0;
        target.style.paddingTop = 0;
        target.style.paddingBottom = 0;
        target.style.marginTop = 0;
        target.style.marginBottom = 0;
        target.offsetHeight;
        target.style.transitionProperty = "height, margin, padding";
        target.style.transitionDuration = duration + 'ms';
        target.style.height = height + 'px';
        target.style.removeProperty('padding-top');
        target.style.removeProperty('padding-bottom');
        target.style.removeProperty('margin-top');
        target.style.removeProperty('margin-bottom');
        target.previousElementSibling.style.pointerEvents = 'none';
        window.setTimeout(() => {
            target.style.removeProperty('height');
            target.style.removeProperty('overflow');
            target.style.removeProperty('transition-duration');
            target.style.removeProperty('transition-property');
            target.previousElementSibling.style.pointerEvents = 'auto';
        }, duration);
        target.parentNode.classList.add('is--open');
    }

    let slideToggleQna = (target, duration = 400) => {
        if (window.getComputedStyle(target).display === 'none') {
            return slideDownQna(target, duration);
        } else {
            return slideUpQna(target, duration);
        }
    }

    // footer accordeon
    const footerTrigger = document.querySelectorAll('.footer-trigger')

    if (footerTrigger) {
        footerTrigger.forEach(item => {
            item.addEventListener('click', (event) => {
                if (window.innerWidth <= 1365) {
                    event.preventDefault()

                    if (!item.parentNode.classList.contains('is--open')) {
                        slideDownQna(item.nextElementSibling)
                    } else {
                        slideUpQna(item.nextElementSibling)
                    }
                }
            })
        })
    }

    // hamburger
    const menu = document.getElementById('menu')
    const hamburgerTrigger = document.getElementById('hamburger-trigger')

    if (menu && hamburgerTrigger) {
        hamburgerTrigger.addEventListener('click', (event) => {
            event.preventDefault()

            if (menu.classList.contains('is--active')) {
                hamburgerTrigger.classList.remove('is--active')
                menu.classList.remove('is--active')
                document.body.classList.remove('scroll-disabled')
            } else {
                hamburgerTrigger.classList.add('is--active')
                menu.classList.add('is--active')
                document.body.classList.add('scroll-disabled')
            }
        })

        menu.addEventListener('click', (event) => {
            if (event.target.classList.contains('menu') || event.target.classList.contains('container')) {
                event.preventDefault()

                if (menu.classList.contains('is--active')) {
                    hamburgerTrigger.classList.remove('is--active')
                    menu.classList.remove('is--active')
                    document.body.classList.remove('scroll-disabled')
                }
            }
        })
    }

    // cookies
    const cookies = document.querySelector('.cookies')
    const cookiesClose = document.querySelector('.js-cookies-close')

    if (cookies && cookiesClose) {
        if (localStorage.cookiesAlert === 'false' || (!('cookiesAlert' in localStorage))) {
            cookies.classList.add('is--active')
        } else {
            cookies.classList.remove('is--active')
        }

        cookiesClose.addEventListener('click', (event) => {
            event.preventDefault()

            if (cookies.classList.contains('is--active')) {
                cookies.classList.remove('is--active')

                localStorage.cookiesAlert = 'true'
            }
        })
    }

    // share
    const share = document.getElementById('share')
    const shareClose = document.querySelector('.js-share-close')
    const shareBtnCopy = document.querySelectorAll('.share-btn-copy')
    const shareInput = document.querySelector('.share-input')
    const shareTrigger = document.querySelectorAll('.share-trigger')

    if (share && shareClose && shareBtnCopy && shareInput && shareTrigger) {
        share.addEventListener('click', (event) => {
            if (event.target.classList.contains('share') || event.target.classList.contains('share-wrap')) {
                event.preventDefault()

                if (share.classList.contains('is--active')) {
                    share.classList.remove('is--active')
                    document.body.classList.remove('scroll-disabled')
                }
            }
        })

        shareClose.addEventListener('click', (event) => {
            event.preventDefault()

            if (share.classList.contains('is--active')) {
                share.classList.remove('is--active')
                document.body.classList.remove('scroll-disabled')
            }
        })

        shareTrigger.forEach(item => {
            item.addEventListener('click', (event) => {
                event.preventDefault()

                if (!share.classList.contains('is--active')) {
                    share.classList.add('is--active')
                    document.body.classList.add('scroll-disabled')

                    shareBtnCopy.forEach(btn => {
                        try{
                            btn.dataset.link = item.dataset.link

                            if(document.querySelector("#share-whatsapp"))
                                document.querySelector("#share-whatsapp").setAttribute('href', `https://api.whatsapp.com/send?text=${item.dataset.link}`)
                            if(document.querySelector("#share-telegram"))
                                document.querySelector("#share-telegram").setAttribute('href', `https://t.me/share/url?url=${item.dataset.link}`)
                            if(document.querySelector("#share-facebook"))
                                document.querySelector("#share-facebook").setAttribute('href', `https://www.facebook.com/sharer.php?u=${item.dataset.link}`)

                            if(document.querySelector("#share-viber"))
                                document.querySelector("#share-viber").setAttribute('href', `viber://forward?text=${item.dataset.link}`)

                            if(document.querySelector("#share-twitter"))
                                document.querySelector("#share-twitter").setAttribute('href', `https://twitter.com/share?url=${item.dataset.link}`)

                            if(document.querySelector("#share-fb-messenger"))
                                document.querySelector("#share-fb-messenger").setAttribute('href', `fb-messenger://share/?link=${item.dataset.link}`)
                        }catch (e){
                            console.log(e)
                        }
                    })
                    shareInput.value = item.dataset.link
                }
            })
        })


        shareBtnCopy.forEach(item => {
            item.addEventListener('click', (event) => {
                event.preventDefault()
                try{
                    //navigator.clipboard.writeText(item.dataset.link)
                    copyText(item.dataset.link)
                    if(document.querySelector("[name=copy_link_of_post_alert]"))
                        alert(document.querySelector("[name=copy_link_of_post_alert]").getAttribute('value'))
                }catch (e){
                    console.log(e)
                }

            })
        })
    }

    // search
    const search = document.getElementById('search')
    const searchTrigger = document.getElementById('search-trigger')

    if (search && searchTrigger) {
        searchTrigger.addEventListener('click', (event) => {
            event.preventDefault()

            if (search.classList.contains('is--active')) {
                searchTrigger.classList.remove('is--active')
                search.classList.remove('is--active')
                document.body.classList.remove('scroll-disabled')
            } else {
                searchTrigger.classList.add('is--active')
                search.classList.add('is--active')
                document.body.classList.add('scroll-disabled')
            }
        })

        search.addEventListener('click', (event) => {
            if (event.target.classList.contains('search') || event.target.classList.contains('container')) {
                event.preventDefault()

                if (search.classList.contains('is--active')) {
                    searchTrigger.classList.remove('is--active')
                    search.classList.remove('is--active')
                    document.body.classList.remove('scroll-disabled')
                }
            }
        })
    }

    // modal promotion
    const modalPromotion = document.getElementById('modal-promotion')
    const modalPromotionClose = document.querySelector('.modal-promotion-close')
    const modalPromotionForm = document.querySelector('.modal-promotion-form')
    const modalPromotionTrigger = document.querySelectorAll('.modal-promotion-trigger')

    function inputValidation(items) {
        let counter = items.length;

        items.forEach(item => {
            if (item.type == 'tel') {
                if ((item.value.replace(/[^+\d]/g, '').length) == 13) {
                    counter--
                }
            } else {
                if (item.value.length >= 2) {
                    counter--
                }
            }
        })

        if (counter == 0) {
            return true;
        } else {
            return false;
        }
    }

    if (modalPromotion && modalPromotionClose && modalPromotionTrigger) {
        modalPromotion.addEventListener('click', (event) => {
            if (event.target.classList.contains('modal-promotion') || event.target.classList.contains('modal-promotion-wrap')) {
                event.preventDefault()

                if (modalPromotion.classList.contains('is--active')) {
                    modalPromotion.classList.remove('is--active')
                    document.body.classList.remove('scroll-disabled')
                }
            }
        })

        modalPromotionClose.addEventListener('click', (event) => {
            event.preventDefault()

            if (modalPromotion.classList.contains('is--active')) {
                modalPromotion.classList.remove('is--active')
                document.body.classList.remove('scroll-disabled')
            }
        })

        modalPromotionTrigger.forEach(item => {
            item.addEventListener('click', (event) => {
                event.preventDefault()

                if (!modalPromotion.classList.contains('is--active')) {
                    modalPromotion.classList.add('is--active')
                    document.body.classList.add('scroll-disabled')
                }
            })
        })
    }

    if (modalPromotionForm) {
        const inputs = modalPromotionForm.querySelectorAll('input[data-required]')

        inputs.forEach(input => {
            input.addEventListener('input', (event) => {
                event.preventDefault()

                if (inputValidation(inputs)) {
                    modalPromotionForm.querySelector('button').removeAttribute('disabled', 'disabled')
                } else {
                    modalPromotionForm.querySelector('button').setAttribute('disabled', 'disabled')
                }
            })
        })

        modalPromotionForm.addEventListener('submit', (event) => {
            if (!inputValidation(inputs)) {
                event.preventDefault()
            }
        })
    }


    // ceo dropdown more
    const ceoDropdownMore = document.querySelectorAll('.ceo-dropdown-trigger')

    if (ceoDropdownMore) {
        ceoDropdownMore.forEach(item => {
            item.addEventListener('click', (event) => {
                event.preventDefault()

                const parent = item.closest('.ceo-dropdown')

                if (parent.classList.contains('is--active')) {
                    parent.classList.remove('is--active')
                    item.innerHTML = item.dataset.value
                } else {
                    parent.classList.add('is--active')
                    item.innerHTML = item.dataset.hide
                }
            })
        })
    }


    // vote widget
    const voteWidgetBtn = document.querySelectorAll('.vote-widget-btn')

    function sendVote(widget) {
        const voteCheckboxes = widget.querySelectorAll('input[type=radio]')
        const voteFlag = widget.querySelector('input[type=radio]:checked')
        const voteBtn = widget.querySelector('.vote-widget-btn')
        const voteValue = widget.querySelector('.vote-widget-value')

        const widgetId = widget.getAttribute('data-widget-id')



        if (voteFlag) {


            const selectedNumber = voteFlag.getAttribute('data-id-of-variant')
            fetch('/send-vote', {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    // 'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: JSON.stringify({'widgetId':widgetId, 'selectedNumber':selectedNumber})
            }).then(function (response){
                return response.json()
            }).then(function (json){

                const number_of_votes = json['number_of_votes'];
                const percents = json['percents'];
                widget.querySelector('.number-of-votes').innerHTML = number_of_votes
                widget.querySelector('.vote-widget-btn').style.display = 'none'
                widget.querySelector('.vote-result').style.display = 'block'
                widget.querySelector('.wrapper-number-of-votes').style.display = 'block'

                for(let percentId in percents){
                    widget.querySelector(`[data-id-of-variant-view-result='${percentId}']`).style.width = `${percents[percentId]}%`
                    widget.querySelector(`[data-id-of-variant-numeric-result='${percentId}']`).innerHTML = `${percents[percentId]}%`
                }

                if (voteCheckboxes) {
                    voteCheckboxes.forEach(radio => {
                        radio.closest('label').classList.add('is--results')
                    })
                }

                voteValue.classList.add('is--active')

                voteBtn.setAttribute('disabled', 'disabled')

                let userVotes = localStorage.userVotes


                if(!userVotes) userVotes = []
                else{
                    userVotes = JSON.parse( userVotes )
                }
                userVotes.push({'widgetId':widgetId, 'voteId':selectedNumber})
                localStorage.userVotes = JSON.stringify(userVotes);
            }).catch(function (e){
                console.log(e)
            })

        }
    }

    if (voteWidgetBtn) {
        voteWidgetBtn.forEach(item => {
            item.addEventListener('click', (event) => {
                event.preventDefault()

                const parent = item.closest('.vote-widget')

                sendVote(parent)
            })
        })
    }

    // select
    const selected = document.querySelectorAll('.select-box__selected')
    const optionsList = document.querySelectorAll('.select-box__option')

    if (selected) {
        selected.forEach((item) => {
            const close = () => {
                document.querySelectorAll('.select-box').forEach((child) => {
                    child.classList.remove('is--active')
                })
            }
            const itemChecker = useItemChecker([item.parentNode, ...selected], close)

            item.addEventListener('click', () => {
                const parent = item.closest('.select-box')

                if (parent.classList.contains('is--active')) {
                    close()
                }
                else {
                    close()

                    parent.classList.add('is--active')
                    itemChecker.setBodyChecker()
                }
            })
        });
    }

    if (optionsList) {
        optionsList.forEach((option) => {
            option.addEventListener('click', (event) => {
                event.preventDefault()

                const parent = option.closest('.select-box')
                const currentSelectedPlaceholder = parent.querySelector('.select-box__placeholder')

                if (option.querySelector('input').checked) {
                    return false;
                } else {
                    option.querySelector('input').checked = true
                }

                currentSelectedPlaceholder.innerHTML = option.querySelector('span').innerHTML

                parent.classList.remove('is--active')
            });
        });
    }



    // apexchart
    function num(number) {
        function number_format(number, decimals, dec_point, thousands_sep) {
            number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
            let n = !isFinite(+number) ? 0 : +number,
                prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
                sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
                dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
                s = '',
                toFixedFix = function (n, prec) {
                    let k = Math.pow(10, prec);
                    return '' + Math.round(n * k) / k;
                };

            s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
            if (s[0].length > 3) {
                s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
            }

            if ((s[1] || '').length < prec) {
                s[1] = s[1] || '';
                s[1] += new Array(prec - s[1].length + 1).join('0');
            }

            return s.join(dec);
        }

        if (number <= 9999 && number >= -9999)  {
            return number_format(number, 0, '.', '');
        } else if(number <= 999999 && number >= -999999){
            return number_format(number/1e3, 2, '.', '') + ' тис. ';
        } else if(number <= 999999999 && number >= -999999999){
            return number_format(number/1e6, 2, '.', '') + ' млн. ';
        } else {
            return number_format(number/1e9, 2, '.', '') + ' млрд.';
        }
    }

    var options = {
        series: [3429000, 3475000, 6323000, 4095000],
        fill: {
            colors: ['#FCCBCB', '#F5B1B1', '#E98E8E', '#CD5E5E'],
        },
        chart: {
            width: '100%',
            type: 'pie',
            plotBorderWidth: 0,
            plotShadow: false,
            expandOnClick: false,
        },
        labels: ["Серпень:", "Вересень:", "Жовтень:", "Листопад:"],
        theme: {
            monochrome: {
                enabled: true
            }
        },
        plotOptions: {
            pie: {
                expandOnClick: false,
                dataLabels: {
                    offset: -25
                }
            }
        },
        dataLabels: {
            textAnchor: 'start',
            style: {
                fontSize: '24px',
                fontFamily: 'Noto Sans, system-ui, sans-serif',
                fontWeight: 'bold',
            },
            dropShadow: {
                enabled: false,
            },
            formatter(val, opts) {
                const name = opts.w.globals.labels[opts.seriesIndex]
                return [name, num(opts.w.globals.series[opts.seriesIndex])]
            },
        },
        legend: {
            show: false
        },
        tooltip: {
            enabled: false
        },
        stroke: {
            show: false,
        },
        responsive: [
            {
                breakpoint: 1199,
                options: {
                    dataLabels: {
                        style: {
                            fontSize: '20px',
                        },
                    },
                },
            },
            {
                breakpoint: 767,
                options: {
                    dataLabels: {
                        style: {
                            fontSize: '14px',
                        },
                    },
                },
            },
        ]
    };



    // scroll
    let prevScrollpos = window.pageYOffset

    function headerScrolldUp() {
        document.querySelector('.header').style.transform = 'translate3d(0px, 0px, 0px)'
    }

    function headerScrollDown() {
        if (window.pageYOffset >= (document.querySelector('.header').getBoundingClientRect().height)) {
            document.querySelector('.header').style.transform = 'translate3d(0px, -100%, 0px)'
        }
    }

    function mobileNavScrollUp() {
        document.querySelector('.mobile-nav').style.transform = 'translate3d(0px, 0px, 0px)'
    }

    function mobileNavScrollDown() {
        document.querySelector('.mobile-nav').style.transform = 'translate3d(0px, 100%, 0px)'
    }

    window.addEventListener('scroll', () => {
        let currentScrollPos = window.pageYOffset;

        if (prevScrollpos > currentScrollPos || prevScrollpos <= 0) { // if up
            if (!document.body.classList.contains('scroll-disabled')) {
                if (document.querySelector('.header')) {
                    headerScrolldUp()
                }
                if (document.querySelector('.mobile-nav')) {
                    mobileNavScrollUp()
                }
            }
        } else { // if down
            if (!document.body.classList.contains('scroll-disabled')) {
                if (document.querySelector('.header')) {
                    headerScrollDown()
                }
                if (document.querySelector('.mobile-nav')) {
                    mobileNavScrollDown()
                }
            }
        }

        prevScrollpos = currentScrollPos;
    })




    addLazyLoading()
})


function copyText(textToCopy)
{
    var textArea = document.createElement("textarea");
    textArea.value = textToCopy;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("Copy");
    textArea.remove();
}




document.querySelectorAll(".widget-button-with-data-href").forEach(function (item){
    item.addEventListener('click', function (){
        if(item.getAttribute('data-href')){
            window.location.href = item.getAttribute('data-href')
        }
    })
})

if(document.querySelector(".weather_select option[selected]") && document.querySelector(".weather_select option[selected]").parentElement){
    var selectedCity = document.querySelector(".weather_select option[selected]");

    let widgetId = document.querySelector(".weather_select option[selected]").parentElement.getAttribute('data-widget-id')
    if(localStorage.getItem('selectedWeatherCity')){
        document.querySelector(`.weather_select[data-widget-id='${widgetId}']`).value=localStorage.getItem('selectedWeatherCity')

    }

    var selectedCityNumber = selectedCity.getAttribute('value')
    printWeather(selectedCityNumber)



    document.querySelectorAll(`.weather_select[data-widget-id='${widgetId}']`).forEach(function (select){
        select.addEventListener('change', function (e){
            localStorage.removeItem('weather')
            printWeather(e.target.value)
            localStorage.setItem('selectedWeatherCity', e.target.value)
        })
    })

    function printWeather(selectedCityNumber)
    {

        if(!localStorage.getItem('weather')){
            getWeatherFromBackend()
        }else{
            let weatherArr = JSON.parse(localStorage.getItem('weather'))
            if(weatherArr[0] && weatherArr[0]['updated_at']){

                let today = new Date();
                today.setHours(0,0,0,0)

                let updated_at_arr = weatherArr[0]['updated_at'].split('.')
                let updated_at_date = null
                let updated_at_month = null
                let updated_at_year = null
                if(updated_at_arr.length === 3){
                    updated_at_date = updated_at_arr[0]
                    updated_at_month = updated_at_arr[1]
                    updated_at_year = updated_at_arr[2]
                }

                const updated_at = new Date(`${updated_at_year}-${updated_at_month}-${updated_at_date}`);
                updated_at.setHours(0,0,0,0)
                if(today>updated_at){
                    localStorage.removeItem('weather')
                    getWeatherFromBackend();
                }else{
                    addWeatherToHtml(weatherArr)
                }
            }else{
                getWeatherFromBackend();
            }

        }


    }

    function getWeatherFromBackend()
    {
        fetch(`/weather_seven_days/${selectedCityNumber}`).then(function (r){
            return r.json()
        }).then(function (arr){
            addWeatherToHtml(arr)
            localStorage.setItem('weather', JSON.stringify(arr))
        })
    }


    function addWeatherToHtml(arr)
    {
        let blocks = document.querySelectorAll(`.weather-block_${widgetId}`);
        blocks.forEach(function (block, index){

            blocks[index].innerHTML =""

        })
        if(arr[0] && arr[0]['updated_at']){

            document.querySelectorAll(`.updated_at_${widgetId}`).forEach(function (item){
                item.innerText = arr[0]['updated_at']
            })
        }
        let lang = document.querySelector("html").getAttribute("lang");

        arr.forEach(function (item, index){
            let className = '';
            if(index === 0){
                className = 'block p-2 min-w-[110px] w-[110px] border border-primary dark:border-white rounded';
            }else{
                className = 'block ml-2 p-2 min-w-[110px] w-[110px] bg-bg dark:bg-bg-black1 border border-bg dark:border-bg-black1 rounded';
            }

            if(item['temperature'] && item['day_of_week'] && item['day_of_week'][lang]){
                /*
                0	Нет осадков
                1	Дождь
                2	Снег
                3	Смешанные осадки*/

                let weathers = {
                    0:'/img/without-precipitation.svg',
                    1:'/img/rain.svg',
                    2:'/img/snow.svg',
                    3:'/img/snow_and_rain.svg'
                };
                let div = `
                        <div class="${className}">
                    <span class="block w-fit text-xs/6 font-medium tracking-[0.66px] uppercase">${item['day_of_week'][lang]}</span>
                    <div class="flex items-center">
                        <img
                         class='weather__icon'
                         alt='${item['description']}'
                         src='${weathers[item['precipitation']]}'>
                        <div class="flex items-center ml-2">
                            <span class="block w-fit text-xs/6 font-bold uppercase">${item['temperature'][0]}°</span>
                            <span class="block ml-1 w-fit text-xs/6 font-normal uppercase">${item['temperature'][1]}°</span>
                        </div>
                    </div>
                </div>
                        `;

                let blocks = document.querySelectorAll(`.weather-block_${widgetId}`);

                blocks.forEach(function (block, index){
                    blocks[index].innerHTML +=div
                })

            }

        })
    }

}
